export default [
  {
    header: "Administrar",
    main: "Administrar",
  },
  {
    main: "Administrar",
    title: "Produtos",
    icon: "bi-box2-heart",
    children: [
      {
        title: "Apostilas",
        route: "products-books-list",
        resource: "Produtos",
        action: "Apostila - Listar",
      },
      {
        title: "Online",
        route: "products-online-list",
        resource: "Produtos",
        action: "Online - Listar",
      },
      {
        title: "Presenciais",
        route: "products-presencials-list",
        resource: "Produtos",
        action: "Presencial - Listar",
      },
      {
        title: "Lives",
        route: "products-lives-list",
        resource: "Produtos",
        action: "Live - Listar",
      },
      {
        title: "Grátis",
        route: "products-free-list",
        resource: "Produtos",
        action: "Grátis - Listar",
      },
      // {
      //   title: "Rápido",
      //   route: "products-fast-list",
      //   resource: "Produtos",
      //   action: "Rápido - Listar",
      // },
      {
        title: "Questões",
        route: "products-questions-list",
        resource: "Produtos",
        action: "Questões - Listar",
      },
      {
        title: "Combo Questões",
        route: "products-combo-list",
        resource: "Produtos",
        action: "Combo Questões - Listar",
      },
      // {
      //   title: 'Coleções',
      //   route: { name: 'product-collections-list' },
      // },
      // {
      //   title: 'Marcas',
      //   route: { name: 'product-brand-list' },
      // },
      // {
      //   title: 'Selos',
      //   route: { name: 'product-seal-list' },
      // },
    ],
  },
  // {
  //   title: 'Análises',
  //   icon: 'TrendingUpIcon',
  //   route: 'apps-invoice-list',
  // },
  {
    main: "Administrar",
    title: "Concursos",
    icon: "bi-journal-text",
    children: [
      {
        title: "Listar",
        route: "contests-list",
        resource: "Concursos",
        action: "Concurso - Listar",
      },
      {
        title: "Fichas Técnicas",
        route: "datasheets-list",
        resource: "Concursos",
        action: "Ficha Técnica",
      },
      {
        title: "Modelos de contrato",
        route: "contract-models-list",
        resource: "Concursos",
        action: "Ficha Técnica",
      },
      {
        title: "Editais",
        route: "notices-list",
        resource: "Concursos",
        action: "Edital",
      },
      {
        title: "Provas",
        route: "proofs-list",
        resource: "Concursos",
        action: "Prova",
      },
      {
        title: "Cargos",
        route: "offices-list",
        resource: "Concursos",
        action: "Cargo",
      },
      {
        title: "Bancas",
        route: "jury-list",
        resource: "Concursos",
        action: "Banca",
      },
      {
        title: "Orgãos",
        route: "organs-list",
        resource: "Concursos",
        action: "Orgão",
      },
      {
        title: "Escolaridades",
        route: "schooling-list",
        resource: "Concursos",
        action: "Escolaridade",
      },
      {
        title: "Estados",
        route: "states-list",
        resource: "Concursos",
        action: "Estado",
      },
      {
        title: "Situações",
        route: { name: "situations-list" },
        resource: "Concursos",
        action: "Situação",
      },
      {
        title: "Áreas",
        route: { name: "areas-list" },
        resource: "Concursos",
        action: "Área",
      },
      {
        title: "Turnos",
        route: { name: "shifts-list" },
        resource: "Concursos",
        action: "Turno",
      },
      {
        title: "Que horas ligar?",
        route: "timetocall-list",
        resource: "Concursos",
        action: "Que horas ligar",
      },
      {
        title: "Como conheceu",
        route: { name: "knows-list" },
        resource: "Concursos",
        action: "Como conheceu",
      },
    ],
  },
  {
    main: "Administrar",
    title: "Clientes",
    icon: "bi bi-person-lines-fill",
    route: "customers-list",
    resource: "Clientes",
    action: "Listar",
  },
  {
    main: "Administrar",
    title: "Leads",
    icon: "bi-people",
    children: [
      {
        title: "Sales Force",
        route: { name: "customers-lead-list" },
        resource: "Captação de Lead",
        action: "Sales Force",
      },
      {
        title: "Passaporte",
        route: { name: "customers-passport-list" },
        resource: "Captação de Lead",
        action: "Passaporte",
      },
      // {
      //   title: 'Ranking',
      //   route: { name: 'customers-ranking-list' },
      // },
    ],
  },
  {
    main: "Administrar",
    title: "Organizar",
    icon: "bi-arrows-move",
    route: "sort-items",
    resource: "Mídia",
    action: "Organizar",
  },
];
