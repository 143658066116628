export default [
  {
    header: "Sistema",
  },
  {
    main: "Sistema",
    title: "Configurações",
    icon: "bi-gear",
    children: [
      {
        title: "Informações",
        route: "config-edit",
        resource: "Sistema",
        action: "Informação",
      },
      {
        title: "CRM",
        route: "config-crm",
        resource: "Sistema",
        action: "Informação",
      },
      {
        title: "Octadesk",
        route: "config-octadesk",
        resource: "Sistema",
        action: "Informação",
      },
      {
        title: "Bling",
        route: "config-bling",
        resource: "Sistema",
        action: "Informação",
      },
      {
        title: "Opens",
        route: "config-opens",
        resource: "Sistema",
        action: "Informação",
      },
      {
        title: "Permissões",
        route: "config-permissions-list",
        resource: "Sistema",
        action: "Permissão",
      },
      {
        title: "Contratos",
        route: "config-contracts-list",
        resource: "Sistema",
        action: "Permissão",
      },
      {
        title: "Sobre",
        route: "config-about-edit",
        resource: "Sistema",
        action: "Sobre",
      },
      {
        title: "Unidades",
        route: "config-unit-list",
        resource: "Sistema",
        action: "Unidade",
      },
      {
        title: "Sales Force",
        route: "config-salesforce-list",
        resource: "Sistema",
        action: "Sales Force",
      },
      {
        title: "Usuários",
        route: { name: "config-users-list" },
        resource: "Sistema",
        action: "Usuário",
      },
      {
        title: "Gestor desconto",
        route: { name: "config-managers-discount-list" },
        resource: "Sistema",
        action: "Gestor de Desconto",
      },
      {
        title: "Tags",
        route: { name: "config-tags-list" },
        resource: "Sistema",
        action: "Tag",
      },
      {
        title: "Parceiros",
        route: "config-partners-list",
        resource: "Sistema",
        action: "Parceiro",
      },
      {
        title: "E-mail",
        route: "config-email",
        resource: "Sistema",
        action: "E-mail",
      },
      {
        title: "Faq",
        route: "config-faq-index",
        resource: "Sistema",
        action: "Faq",
      },
      {
        title: "P. de Privacidade",
        route: "config-privacy-policy-index",
        resource: "Sistema",
        action: "Política de Privacidade",
      },
    ],
  },
];
