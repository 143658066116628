export default [
  {
    header: "Dashboard",
    main: "Dashboard",
  },
  {
    main: 'Dashboard',
    title: "Dashboard",
    
    icon: "bi-speedometer2",
    // tag: '2',
    // tagVariant: 'light-warning',
    route: "dashboard-ecommerce",
    resource: "Dashboard",
    action: "Dashboard",
  },
];
