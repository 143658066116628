<template>
  <li class="nav-item nav-search">
    <a class="nav-link" v-b-modal.modal-discount>
      <i class="bi bi-percent pl-1 pr-1" style="font-size: 1.3rem;"></i>
    </a>
  </li>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
};
</script>
