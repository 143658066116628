import { useUtils as useAclUtils } from "@core/libs/acl";

const { canViewVerticalNavMenuHeader } = useAclUtils();

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: "",
    };
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
  },
  render(h) {
    const span = h("span", {}, this.item.header);
    const icon = h("feather-icon", {
      props: { icon: "MoreHorizontalIcon", size: "18" },
    });

    const ability = this.userData.ability;

    const hasPermissionForHeader = ability?.some((permission) => {
      return (
        permission.subject === this.item.header && permission.actions.length > 0
      );
    });

    if (!hasPermissionForHeader) {
      return h();
    } else {
      return h("li", { class: "navigation-header text-truncate" }, [
        span,
        icon,
      ]);
    }
  },
};
