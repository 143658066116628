var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$route.meta.breadcrumb
    ? _c(
        "div",
        {
          staticClass:
            "form-row content-header mb-2 justify-content-between content-header-breadcrumb",
        },
        [
          _c(
            "b-col",
            { staticClass: "content-header-left col-12 col-md-7 d-flex-left" },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c("b-breadcrumb-item", { attrs: { to: "/" } }, [
                              _c("i", { staticClass: "bi bi-house" }),
                            ]),
                            _vm._l(_vm.$route.meta.breadcrumb, function (item) {
                              return _c(
                                "b-breadcrumb-item",
                                {
                                  key: item.text,
                                  attrs: { active: item.active, to: item.to },
                                },
                                [_vm._v(" " + _vm._s(item.text) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "content-header-right text-md-right col-12 col-md-5",
            },
            [
              _c("div", { staticClass: "form-row justify-content-end" }, [
                _vm.$route.meta.btnBack || _vm.$route.meta.btnBackParams
                  ? _c(
                      "div",
                      { staticClass: "col-md-2" },
                      [
                        _vm.$route.meta.btnBack
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-secondary btn-block",
                                attrs: {
                                  to: { name: "" + _vm.$route.meta.btnBack },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-nowrap" },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ChevronLeftIcon" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.$route.meta.btnBackParams
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-secondary btn-block",
                                attrs: {
                                  to: {
                                    name: "" + _vm.$route.meta.btnBackParams,
                                    params: { uuid: _vm.$route.params.uuid },
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-nowrap" },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ChevronLeftIcon" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.btnSubcategoryProduct
                  ? _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "btn btn-block",
                            class:
                              "btn-" +
                              _vm.$route.meta.btnSubcategoryProduct.variant,
                            attrs: {
                              to: {
                                name:
                                  "" +
                                  _vm.$route.meta.btnSubcategoryProduct.route,
                                params: {
                                  uuid:
                                    "" +
                                    _vm.$route.meta.btnSubcategoryProduct.uuid,
                                },
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "text-nowrap" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$route.meta.btnSubcategoryProduct.text
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.btnSubcategory
                  ? _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        (
                          _vm.$route.meta.btnSubcategory.permission
                            ? _vm.$can(
                                _vm.$route.meta.btnSubcategory.permission
                                  .action,
                                _vm.$route.meta.btnSubcategory.permission
                                  .resource
                              )
                            : true
                        )
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn btn-block",
                                class:
                                  "btn-" +
                                  _vm.$route.meta.btnSubcategory.variant,
                                attrs: {
                                  to: {
                                    name:
                                      "" + _vm.$route.meta.btnSubcategory.route,
                                    params: {
                                      uuid:
                                        "" +
                                        _vm.$route.meta.btnSubcategory.uuid,
                                    },
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$route.meta.btnSubcategory.text
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.btnPrintOut
                  ? _c(
                      "div",
                      { staticClass: "col-md-3 col-4" },
                      [
                        _vm.$route.meta.btnPrintOut &&
                        _vm.$route.meta.btnPrintOut.id
                          ? _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "light" },
                                on: { click: _vm.btnPrintOutAction },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v("Imprimir"),
                                ]),
                              ]
                            )
                          : _vm.$route.meta.btnCreateRouteName
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn btn-block btn-primary",
                                attrs: {
                                  to: {
                                    name:
                                      "" + _vm.$route.meta.btnCreateRouteName,
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v("Cadastrar"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.modal || _vm.$route.meta.btnCreateRouteName
                  ? _c(
                      "div",
                      { staticClass: "col-md-3 col-4" },
                      [
                        _vm.$route.meta.modal &&
                        _vm.$route.meta.modal.btnCreate &&
                        !_vm.$route.meta.modal.permission
                          ? _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "primary" },
                                on: { click: _vm.btnCreateModalAction },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v("Cadastrar"),
                                ]),
                              ]
                            )
                          : _vm.$route.meta.modal &&
                            _vm.$route.meta.modal.btnCreate &&
                            _vm.$route.meta.modal.permission &&
                            _vm.$can(
                              _vm.$route.meta.modal.permission.action,
                              _vm.$route.meta.modal.permission.resource
                            )
                          ? _c(
                              "b-button",
                              {
                                attrs: { block: "", variant: "primary" },
                                on: { click: _vm.btnCreateModalAction },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v("Cadastrar"),
                                ]),
                              ]
                            )
                          : _vm.$route.meta.btnCreateRouteName
                          ? _c(
                              "router-link",
                              {
                                staticClass: "btn btn-block btn-primary",
                                attrs: {
                                  to: {
                                    name:
                                      "" + _vm.$route.meta.btnCreateRouteName,
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "text-nowrap" }, [
                                  _vm._v("Cadastrar"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.btnOnlyExport
                  ? _c("div", { staticClass: "col-md-4 col-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning",
                          attrs: { type: "button" },
                          on: { click: _vm.btnOnlyExportAction },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "DownloadIcon" },
                          }),
                          _vm._v(" Exportar "),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.$route.meta.btnExport || _vm.$route.meta.btnImport
                  ? _c(
                      "div",
                      { staticClass: "col-md-2 col-3" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass:
                              "dropdown-icon-wrapper dropdown-customer-actions",
                            attrs: {
                              block: "",
                              right: "",
                              variant: "outline-secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          _c("feather-icon", {
                                            attrs: { icon: "SettingsIcon" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1758287290
                            ),
                          },
                          [
                            _vm.$route.meta.btnExport
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.btnExportAction } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "DownloadIcon" },
                                    }),
                                    _c("span", [_vm._v("Exportar")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.$route.meta.btnImport
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.btnImportAction } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "mr-50",
                                      attrs: { icon: "UploadIcon" },
                                    }),
                                    _c("span", [_vm._v("Importar")]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.options
                  ? _c(
                      "div",
                      { staticClass: "col-md-2 col-3" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass:
                              "dropdown-icon-wrapper dropdown-menu-customer",
                            attrs: {
                              block: "",
                              right: "",
                              variant: "outline-secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          !_vm.$route.meta.options.icon
                                            ? _c("feather-icon", {
                                                attrs: { icon: "SettingsIcon" },
                                              })
                                            : _c("i", {
                                                class:
                                                  _vm.$route.meta.options.icon,
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4055156245
                            ),
                          },
                          [
                            _vm.$route.meta.options.addCart &&
                            _vm.$can("Gerenciar Carrinho", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: { click: _vm.btnCustomerAddCartAction },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-cart2 mr-50",
                                    }),
                                    _c("span", [_vm._v("Gerenciar Carrinho")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.meta.options.addProduct &&
                            _vm.$can("Cortesia - EAD", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: {
                                      click: _vm.btnCustomerAddProductAction,
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-box2-heart mr-50",
                                    }),
                                    _c("span", [_vm._v("Cortesia - EAD")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.meta.options.addGrid &&
                            _vm.$can("Cortesia - Pedagógico", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    on: { click: _vm.btnCustomerAddGridAction },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-calendar3 mr-50",
                                    }),
                                    _c("span", [
                                      _vm._v("Cortesia - Pedagógico"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.meta.options.routeEnrollment &&
                            _vm.$can("Matricular", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    attrs: {
                                      to: {
                                        name:
                                          "" +
                                          _vm.$route.meta.options
                                            .routeEnrollment,
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "bi bi-headset mr-50",
                                    }),
                                    _c("span", [_vm._v("Matricular Aluno")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$route.meta.options.login &&
                            _vm.$can("Acessar Conta", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.btnCustomerLoginAction } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-box-arrow-in-right mr-50",
                                    }),
                                    _c("span", [_vm._v("Acessar Conta")]),
                                  ]
                                )
                              : _vm._e(),
                            _vm.$can("Excluir", "Clientes")
                              ? _c("hr")
                              : _vm._e(),
                            _vm.$can("Excluir", "Clientes")
                              ? _c(
                                  "b-dropdown-item",
                                  {
                                    staticClass: "text-danger",
                                    on: { click: _vm.btnCustomerDeleteAction },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bi bi-trash mr-50 text-danger",
                                    }),
                                    _c("span", { staticClass: "text-danger" }, [
                                      _vm._v("Excluir"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.optionsPurchase
                  ? _c(
                      "div",
                      { staticClass: "col-md-2 col-3" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass:
                              "dropdown-icon-wrapper dropdown-menu-customer",
                            attrs: {
                              block: "",
                              right: "",
                              variant: "outline-secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          !_vm.$route.meta.optionsPurchase.icon
                                            ? _c("feather-icon", {
                                                attrs: { icon: "SettingsIcon" },
                                              })
                                            : _c("i", {
                                                class:
                                                  _vm.$route.meta
                                                    .optionsPurchase.icon,
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3353630197
                            ),
                          },
                          _vm._l(
                            _vm.$route.meta.optionsPurchase,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: index },
                                [
                                  _vm.$can(
                                    "" + item.permission.action,
                                    "" + item.permission.resource
                                  )
                                    ? _c(
                                        "b-dropdown-item",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: { name: "" + item.route },
                                              },
                                            },
                                            [
                                              item.icon
                                                ? _c("i", {
                                                    class:
                                                      "bi " +
                                                      item.icon +
                                                      " mr-50",
                                                  })
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(_vm._s(item.text)),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$route.meta.dropdown
                  ? _c(
                      "div",
                      { staticClass: "col-md-2 col-3" },
                      [
                        _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(186, 191, 199, 0.15)",
                                expression: "'rgba(186, 191, 199, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            staticClass:
                              "dropdown-icon-wrapper dropdown-menu-customer",
                            attrs: {
                              block: "",
                              right: "",
                              variant: "outline-secondary",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        [
                                          !_vm.$route.meta.dropdown.icon
                                            ? _c("feather-icon", {
                                                attrs: { icon: "SettingsIcon" },
                                              })
                                            : _c("i", {
                                                class:
                                                  _vm.$route.meta.dropdown.icon,
                                              }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3181489237
                            ),
                          },
                          _vm._l(
                            _vm.$route.meta.dropdown,
                            function (item, index) {
                              return _c(
                                "b-dropdown-item",
                                { key: index },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: { name: "" + item.route } },
                                    },
                                    [
                                      item.icon
                                        ? _c("i", {
                                            class: "bi " + item.icon + " mr-50",
                                          })
                                        : _vm._e(),
                                      _c("span", [_vm._v(_vm._s(item.text))]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }