<template>
  <div
    v-if="$route.meta.breadcrumb"
    class="form-row content-header mb-2 justify-content-between content-header-breadcrumb"
  >
    <b-col class="content-header-left col-12 col-md-7 d-flex-left">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <i class="bi bi-house"></i>
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <b-col class="content-header-right text-md-right col-12 col-md-5">
      <div class="form-row justify-content-end">
        <div
          class="col-md-2"
          v-if="$route.meta.btnBack || $route.meta.btnBackParams"
        >
          <router-link
            v-if="$route.meta.btnBack"
            class="btn btn-outline-secondary btn-block"
            :to="{ name: `${$route.meta.btnBack}` }"
          >
            <span class="text-nowrap"
              ><feather-icon icon="ChevronLeftIcon"
            /></span>
          </router-link>
          <router-link
            v-if="$route.meta.btnBackParams"
            class="btn btn-outline-secondary btn-block"
            :to="{
              name: `${$route.meta.btnBackParams}`,
              params: { uuid: $route.params.uuid },
            }"
          >
            <span class="text-nowrap"
              ><feather-icon icon="ChevronLeftIcon"
            /></span>
          </router-link>
        </div>

        <div class="col-md-4" v-if="$route.meta.btnSubcategoryProduct">
          <router-link
            class="btn btn-block"
            :class="`btn-${$route.meta.btnSubcategoryProduct.variant}`"
            :to="{
              name: `${$route.meta.btnSubcategoryProduct.route}`,
              params: { uuid: `${$route.meta.btnSubcategoryProduct.uuid}` },
            }"
          >
            <span class="text-nowrap">
              {{ $route.meta.btnSubcategoryProduct.text }}
            </span>
          </router-link>
        </div>

        <div class="col-md-4" v-if="$route.meta.btnSubcategory">
          <router-link
            class="btn btn-block"
            :class="`btn-${$route.meta.btnSubcategory.variant}`"
            :to="{
              name: `${$route.meta.btnSubcategory.route}`,
              params: { uuid: `${$route.meta.btnSubcategory.uuid}` },
            }"
            v-if="
              $route.meta.btnSubcategory.permission ? 
                $can($route.meta.btnSubcategory.permission.action, $route.meta.btnSubcategory.permission.resource)
              : true
            "
          >
            <span class="text-nowrap">
              {{ $route.meta.btnSubcategory.text }}
            </span>
          </router-link>
        </div>

        <div v-if="$route.meta.btnPrintOut" class="col-md-3 col-4">
          <b-button
            v-if="$route.meta.btnPrintOut && $route.meta.btnPrintOut.id"
            block
            variant="light"
            @click="btnPrintOutAction"
          >
            <span class="text-nowrap">Imprimir</span>
          </b-button>

          <router-link
            v-else-if="$route.meta.btnCreateRouteName"
            class="btn btn-block btn-primary"
            :to="{ name: `${$route.meta.btnCreateRouteName}` }"
          >
            <span class="text-nowrap">Cadastrar</span>
          </router-link>
        </div>

        <div
          v-if="$route.meta.modal || $route.meta.btnCreateRouteName"
          class="col-md-3 col-4"
        >
          <b-button
            v-if="
              $route.meta.modal &&
              $route.meta.modal.btnCreate &&
              !$route.meta.modal.permission
            "
            block
            variant="primary"
            @click="btnCreateModalAction"
          >
            <span class="text-nowrap">Cadastrar</span>
          </b-button>

          <b-button
            v-else-if="
              $route.meta.modal &&
              $route.meta.modal.btnCreate &&
              $route.meta.modal.permission &&
              $can(
                $route.meta.modal.permission.action,
                $route.meta.modal.permission.resource
              )
            "
            block
            variant="primary"
            @click="btnCreateModalAction"
          >
            <span class="text-nowrap">Cadastrar</span>
          </b-button>

          <router-link
            v-else-if="$route.meta.btnCreateRouteName"
            class="btn btn-block btn-primary"
            :to="{ name: `${$route.meta.btnCreateRouteName}` }"
          >
            <span class="text-nowrap">Cadastrar</span>
          </router-link>
        </div>

        <div
          class="col-md-4 col-3"
          v-if="$route.meta.btnOnlyExport"
        >
          <button @click="btnOnlyExportAction" type="button" class="btn btn-warning">
            <feather-icon icon="DownloadIcon" class="mr-50" />
            Exportar
          </button>
        </div>
        
        <div
          class="col-md-2 col-3"
          v-if="$route.meta.btnExport || $route.meta.btnImport"
        >
          <b-dropdown
            block
            right
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="dropdown-icon-wrapper dropdown-customer-actions"
            variant="outline-secondary"
          >
            <template #button-content>
              <span>
                <feather-icon icon="SettingsIcon" />
              </span>
            </template>
            <b-dropdown-item
              v-if="$route.meta.btnExport"
              @click="btnExportAction"
            >
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span>Exportar</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$route.meta.btnImport"
              @click="btnImportAction"
            >
              <feather-icon icon="UploadIcon" class="mr-50" />
              <span>Importar</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="col-md-2 col-3" v-if="$route.meta.options">
          <b-dropdown
            block
            right
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="dropdown-icon-wrapper dropdown-menu-customer"
            variant="outline-secondary"
          >
            <template #button-content>
              <span>
                <feather-icon
                  v-if="!$route.meta.options.icon"
                  icon="SettingsIcon"
                />
                <i v-else :class="$route.meta.options.icon"></i>
              </span>
            </template>

            <b-dropdown-item
              v-if="
                $route.meta.options.addCart &&
                $can('Gerenciar Carrinho', 'Clientes')
              "
              @click="btnCustomerAddCartAction"
            >
              <i class="bi bi-cart2 mr-50"></i>
              <span>Gerenciar Carrinho</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $route.meta.options.addProduct &&
                $can('Cortesia - EAD', 'Clientes')
              "
              @click="btnCustomerAddProductAction"
            >
              <i class="bi bi-box2-heart mr-50"></i>
              <span>Cortesia - EAD</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $route.meta.options.addGrid &&
                $can('Cortesia - Pedagógico', 'Clientes')
              "
              @click="btnCustomerAddGridAction"
            >
              <i class="bi bi-calendar3 mr-50"></i>
              <span>Cortesia - Pedagógico</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $route.meta.options.routeEnrollment &&
                $can('Matricular', 'Clientes')
              "
              :to="{
                name: `${$route.meta.options.routeEnrollment}`,
              }"
            >
              <i class="bi bi-headset mr-50"></i>
              <span>Matricular Aluno</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $route.meta.options.login && $can('Acessar Conta', 'Clientes')
              "
              @click="btnCustomerLoginAction"
            >
              <i class="bi bi-box-arrow-in-right mr-50"></i>
              <span>Acessar Conta</span>
            </b-dropdown-item>
            <hr v-if="$can('Excluir', 'Clientes')" />
            <b-dropdown-item
              @click="btnCustomerDeleteAction"
              v-if="$can('Excluir', 'Clientes')"
              class="text-danger"
            >
              <i class="bi bi-trash mr-50 text-danger"></i>
              <span class="text-danger">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="col-md-2 col-3" v-if="$route.meta.optionsPurchase">
          <b-dropdown
            block
            right
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="dropdown-icon-wrapper dropdown-menu-customer"
            variant="outline-secondary"
          >
            <template #button-content>
              <span>
                <feather-icon
                  v-if="!$route.meta.optionsPurchase.icon"
                  icon="SettingsIcon"
                />
                <i v-else :class="$route.meta.optionsPurchase.icon"></i>
              </span>
            </template>

            <div v-for="(item, index) in $route.meta.optionsPurchase"
            :key="index">
              <b-dropdown-item
                v-if="$can(`${item.permission.action}`, `${item.permission.resource}`)"
              >
                <router-link  :to="{ name: `${item.route}` }">
                  <i v-if="item.icon" :class="`bi ${item.icon} mr-50`"></i>
                  <span>{{ item.text }}</span>
                </router-link>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </div>

        <div class="col-md-2 col-3" v-if="$route.meta.dropdown">
          <b-dropdown
            block
            right
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="dropdown-icon-wrapper dropdown-menu-customer"
            variant="outline-secondary"
          >
            <template #button-content>
              <span>
                <feather-icon
                  v-if="!$route.meta.dropdown.icon"
                  icon="SettingsIcon"
                />
                <i v-else :class="$route.meta.dropdown.icon"></i>
              </span>
            </template>

            <b-dropdown-item
              v-for="(item, index) in $route.meta.dropdown"
              :key="index"
            >
              <router-link :to="{ name: `${item.route}` }">
                <i v-if="item.icon" :class="`bi ${item.icon} mr-50`"></i>
                <span>{{ item.text }}</span>
              </router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data() {
    return {
      isActive: false,
      isBtnExportActive: false,
      isBtnOnlyExportActive: false,
      isBtnImportActive: false,
      isBtnCustomerAddProductActive: false,
      isBtnCustomerAddCartActive: false,
      isBtnCustomerAddGridActive: false,
      isBtnPrintOutActive: false,
    };
  },
  methods: {
    btnPrintOutAction() {
      this.$root.$emit("open:btn-print-out", {
        status: this.isBtnPrintOutActive,
        elementName: this.$route.meta.btnPrintOut.id,
      });
    },
    btnCreateAction() {
      this.isActive ? false : true;
      this.$root.$emit("open:sidebar", {
        status: this.isActive,
        elementName: this.$route.meta.btnCreateElementName,
      });
    },
    btnCreateModalAction() {
      this.isActive ? false : true;
      this.$root.$emit(`open:${this.$route.meta.modal.btnCreateModalId}`, {
        status: this.isActive,
        elementName: this.$route.meta.modal.btnCreateModalId,
        permission: this.$route.meta.modal?.permission,
      });
    },
    btnImportAction() {
      this.thisisBtnImportActive ? false : true;
      this.$root.$emit("open:modal-import", { status: this.isBtnImportActive });
    },
    btnExportAction() {
      this.isBtnExportActive ? false : true;
      this.$root.$emit("open:export-table", { status: this.isBtnExportActive });
    },
    btnOnlyExportAction() {
      this.isBtnOnlyExportActive ? false : true;
      this.$root.$emit("open:export-table", { status: this.isBtnOnlyExportActive });
    },
  },
};
</script>

<style lang="scss">
.dropdown-menu-customer,
.dropdown-customer-actions {
  ul.dropdown-menu {
    a.dropdown-item {
      display: flex;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      i.bi {
        height: auto;
        width: auto;
        font-size: 1.2rem;
      }
    }
  }
}
</style>
