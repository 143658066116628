import dashboard from "./dashboard";
import primary from "./primary";
import blog from "./blog";
import medias from "./medias";
import config from "./config";
import pedagogical from "./pedagogical";
import orders from "./orders";
import pedagogicalUnidades from "./pegadogico-unidades";
import purchase from "./purchase";

export default [
  ...dashboard,
  ...primary,
  ...blog,
  ...pedagogical,
  ...pedagogicalUnidades,
  ...medias,
  ...orders,
  ...purchase,
  ...config,
];
