export default [
  {
    header: "Mídia",
  },
  {
    main: 'Mídia',
    title: "Banners",
    icon: "bi-images",
    route: "banner-list",
    resource: "Mídia",
    action: "Banner",
  },
  {
    main: 'Mídia',
    title: "Arquivos",
    icon: "bi-stack",
    route: "media-list",
    resource: "Mídia",
    action: "Arquivo",
  },
  {
    main: 'Mídia',
    title: "Avisos",
    icon: "bi-chat-dots",
    route: "warning-list",
    resource: "Mídia",
    action: "Arquivo",
  },
];
