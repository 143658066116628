var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("p", { staticClass: "clearfix mb-0" }, [
    _c(
      "span",
      { staticClass: "float-md-left d-block d-md-inline-block mt-25" },
      [
        _vm._v(
          " " +
            _vm._s(this.school.name) +
            " - © " +
            _vm._s(new Date().getFullYear()) +
            " "
        ),
        _c(
          "b-link",
          {
            staticClass: "ml-25",
            attrs: { href: "https://seducar.com.br", target: "_blank" },
          },
          [_vm._v("Seducar")]
        ),
        _c("span", { staticClass: "d-none d-sm-inline-block" }, [
          _vm._v(", Todos os direitos reservados"),
        ]),
      ],
      1
    ),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "float-md-right d-none d-md-block" }, [
      _vm._v(" Feito com "),
      _c("i", { staticClass: "bi bi-chat-square-heart" }),
      _vm._v(" no RJ "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }