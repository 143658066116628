var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item nav-search" }, [
    _c(
      "a",
      {
        directives: [
          {
            name: "b-modal",
            rawName: "v-b-modal.modal-discount",
            modifiers: { "modal-discount": true },
          },
        ],
        staticClass: "nav-link",
      },
      [
        _c("i", {
          staticClass: "bi bi-percent pl-1 pr-1",
          staticStyle: { "font-size": "1.3rem" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }