export default [
  {
    header: "Pedagógico",
    icon: "bi-card-list",
  },
  {
    title: "Passaportes",
    route: "products-passport-list",
    icon: "bi bi-pass",
    resource: "Produtos",
    action: "Passaporte - Listar",
  },
  {
    title: "Cursos Venda",
    icon: "bi-journal-bookmark",
    route: "courses-sale-list",
    resource: "Pedagógico",
    action: "Curso Venda - Listar",
  },
  {
    title: "Disciplinas P/L",
    icon: "bi-ui-checks",
    route: "course-disciplines-list",
    resource: "Pedagógico",
    action: "Disciplina - Listar",
  },
  {
    title: "Cursos",
    icon: "bi-columns-gap",
    route: "courses-list",
    resource: "Pedagógico",
    action: "Curso - Listar",
  },
  {
    title: "Turmas",
    icon: "bi-people",
    route: "classrooms-list",
    resource: "Pedagógico",
    action: "Turma - Listar",
  },
  {
    title: "Grades",
    icon: "bi-calendar3",
    route: "grids-list",
    resource: "Pedagógico",
    action: "Grade - Listar",
  },
  {
    title: "Professores",
    icon: "bi-person-video3",
    route: "teachers-list",
    resource: "Professor",
    action: "Listar",
  },
  {
    title: "Materiais",
    icon: "bi-folder",
    route: "materials-v2-list",
    resource: "Pedagógico",
    action: "Material - Listar",
  },
  {
    title: "Pontos do Professor",
    icon: "bi-clock-history",
    route: "teacher-points-list",
    resource: "Pedagógico",
    action: "Ponto do Professor - Listar",
  },
  {
    title: "Gerar Relatório de Pontos",
    icon: "bi-file-earmark-bar-graph",
    route: "teacher-points-generate",
    resource: "Pedagógico",
    action: "Ponto do Professor - Gerar Relatório",
  },
  {
    title: "Relatórios de Pontos",
    icon: "bi bi-file-earmark-text",
    route: "teacher-points-reports",
    resource: "Pedagógico",
    action: "Relatório de Ponto - Listar",
  },
  {
    title: "Aulas por Professor",
    icon: "bi-person-video3",
    route: "teacher-lessons-list",
    resource: "Pedagógico",
    action: "Aulas por Professor",
  },
  {
    title: "Início das Turmas",
    icon: "bi-calendar2-date",
    route: "start-classes-list",
    resource: "Pedagógico",
    action: "Início das Turmas",
  },
  {
    title: "Painel de Vendas",
    icon: "bi-diagram-3",
    route: "sales-panel-index",
    resource: "Pedagógico",
    action: "Painel de Vendas",
  },
  {
    title: "Sincronizar Pedidos",
    icon: "bi-arrow-repeat",
    route: "sync-orders-index",
    resource: "Pedagógico",
    action: "Sincronizar Pedidos",
  },
];
