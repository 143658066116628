export default [
  {
    header: "Comercial",
  },
  {
    main: "Comercial",
    title: "Vendas",
    icon: "bi-graph-up-arrow",
    children: [
      {
        title: "Pedidos",
        route: "transactions-orders-list",
        resource: "Comercial",
        action: "Pedido - Listar",
      },
      {
        title: "Cancelamentos",
        route: "transactions-orders-cancellations-list",
        resource: "Comercial",
        action: "Cancelamento - Listar",
      },
      {
        title: "Parcelas",
        route: "transactions-orders-installments-list",
        resource: "Comercial",
        action: "Parcela - Listar",
      },
      {
        title: "Cartas de Crédito",
        route: "orders-letters-credit-list",
        resource: "Comercial",
        action: "Pedido - Cartas de Crédito",
      },
      {
        title: "Matrículas",
        route: "transactions-orders-enrollments-list",
        resource: "Comercial",
        action: "Matrícula - Listar",
      },
      {
        title: "Por Curso",
        route: "transactions-orders-courses-list",
        resource: "Comercial",
        action: "Por Curso - Listar",
      },
      {
        title: "Por Produto",
        route: "orders-products-list",
        resource: "Comercial",
        action: "Por Produto - Listar",
      },
      {
        title: "Tickets Desconto",
        route: "tickets-discount-list",
        resource: "Comercial",
        action: "Ticket Desconto - Listar",
      },
      {
        title: "Indicações",
        route: "indications-list",
        resource: "Comercial",
        action: "Indicação - Listar",
      },
    ],
  },
  {
    main: "Comercial",
    title: "Descontos",
    icon: "bi-percent",
    children: [
      {
        title: "Cupons",
        route: "coupons-list",
        resource: "Comercial",
        action: "Cupom - Listar",
      },
      {
        title: "Campanhas",
        route: "discounts-list",
        resource: "Comercial",
        action: "Campanha - Listar",
      },
    ],
  },

  {
    main: "Comercial",
    title: "Adquirentes",
    icon: "bi-bank",
    route: "acquirers-list",
    resource: "Adquirentes",
    action: "Listar",
  },
  {
    main: "Comercial",
    title: "Repasses",
    icon: "bi-file-earmark-richtext",
    children: [
      {
        title: "Criar",
        route: "transactions-remittances-create",
        resource: "Comercial",
        action: "Repasse - Listar",
      },
      {
        title: "Aprovação Gerente",
        route: "transactions-remittances-manager-approve-list",
        resource: "Comercial",
        action: "Repasse - Listar",
      },
      {
        title: "Aprovação Financeiro",
        route: "transactions-remittances-finance-approve-list",
        resource: "Comercial",
        action: "Repasse - Listar",
      },
      {
        title: "Aprovados",
        route: "transactions-remittances-approved-list",
        resource: "Comercial",
        action: "Repasse - Listar",
      },
    ],
  },
];
