<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ this.school.name }} - 
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://seducar.com.br"
        target="_blank"
      >Seducar</b-link>
      <span class="d-none d-sm-inline-block">, Todos os direitos reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block">
      Feito com
      <i class="bi bi-chat-square-heart"></i>
      no RJ
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
  },
  data() {
    return {
      school: {
        name: '',
      },
    }
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.school.name = userData.school.name ?? '';
  }
};
</script>
