export default [
  {
    header: "Blog",
    main: "Blog",
  },
  
      {
        title: "Posts",
        route: "blog-posts-list",
        icon: 'bi bi-newspaper',
        resource: "Blog",
        action: "Post",
      },
      {
        title: "Áreas",
        route: "blog-areas-list",
        icon: 'bi bi-view-list',
        resource: "Blog",
        action: "Área",
      },
      {
        title: "Jornalistas",
        icon: 'bi bi-person-vcard',
        route: "blog-authors-list",
        resource: "Blog",
        action: "Jornalista",
      },
];
