export default [
  {
    header: "Financeiro - ADM",
  },
  {
    main: "Financeiro - ADM",
    title: "Contas",
    icon: "bi-receipt",
    children: [
      {
        title: "Fornecedores",
        route: "suppliers-list",
        resource: "Financeiro - ADM",
        action: "Fornecedor - Listar",
      },
      {
        title: "Clientes",
        route: "clients-list",
        resource: "Financeiro - ADM",
        action: "Clientes - Listar",
      },
      {
        title: "Unidade Estratégica",
        route: "strategic-units-list",
        resource: "Financeiro - ADM",
        action: "Unidade Estratégica - Listar",
      },
      {
        title: "Unidades de Negócio",
        route: "business-units-list",
        resource: "Financeiro - ADM",
        action: "Unidade de Negócio - Listar",
      },
      {
        title: "Calendário",
        route: "bills-calendar",
        resource: "Financeiro - ADM",
        action: "Calendário - Listar",
      },
      {
        title: "Empresas",
        route: "companies-list",
        resource: "Financeiro - ADM",
        action: "Empresa - Listar",
      },
      {
        title: "Contas bancárias",
        route: "orders-purchases-bank-account-list",
        resource: "Financeiro - ADM",
        action: "Conta Bancária - Listar",
      },
      {
        title: "Vindi",
        route: "orders-purchases-vindi-list",
        resource: "Financeiro - ADM",
        action: "Vindi - Listar",
      },
    ],
  },
  {
    path: "/companies/list",
    name: "companies-list",
    component: () => import("@/views/finance/company/List.vue"),
    meta: {
      resource: "Contas",
      action: "Pedidos de Compra - Status",
      pageTitle: "Empresas",
      btnCreateRouteName: "companies-create",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "Contas",
        },
        {
          text: "Empresas",
          active: true,
        },
      ],
    },
  },
  {
    main: "Financeiro - ADM",
    title: "Pedidos de Compra",
    icon: "bi-receipt-cutoff",
    children: [
      {
        title: "Lançados",
        route: "orders-purchases-list",
        resource: "Financeiro - ADM",
        action: "Lançado - Listar",
      },
      {
        title: "Para Aprovação",
        route: "orders-purchases-approved-list",
        resource: "Financeiro - ADM",
        action: "Para Aprovação - Listar",
      },
      {
        title: "Aprovados",
        route: "orders-purchases-approved-purchases-list",
        resource: "Financeiro - ADM",
        action: "Aprovado - Listar",
      },
      {
        title: "Pagamentos",
        route: "orders-purchases-payments-list",
        resource: "Financeiro - ADM",
        action: "Pagamento - Listar",
      },
      {
        title: "Dar Baixa",
        route: "orders-purchases-write-off-payment-list",
        resource: "Financeiro - ADM",
        action: "Dar Baixa - Listar",
      },
      {
        title: "Romaneio Lançamento",
        route: "orders-purchases-romaneio-list",
        resource: "Financeiro - ADM",
        action: "Romaneio - Listar",
      },
      {
        title: "Romaneio Pagamentos",
        route: "orders-purchases-romaneio-payments-list",
        resource: "Financeiro - ADM",
        action: "Romaneio Pagamentos - Listar",
      },
    ],
  },
  {
    main: "Financeiro - ADM",
    title: "Cancelamento de Matrículas",
    icon: "bi-receipt-cutoff",
    children: [
      {
        title: "Solicitado",
        route: "orders-purchases-cancellations-list",
        resource: "Financeiro - ADM",
        action: "Cancelamento Solicitado - Listar",
      },
      {
        title: "Conferido",
        route: "orders-purchases-cancellations-confirmed-list",
        resource: "Financeiro - ADM",
        action: "Cancelamento Conferido - Listar",
      },
      {
        title: "Aprovado",
        route: "orders-purchases-cancellations-approved-list",
        resource: "Financeiro - ADM",
        action: "Cancelamento Aprovado - Listar",
      },
      {
        title: "Efetivado",
        route: "orders-purchases-cancellations-effectived-list",
        resource: "Financeiro - ADM",
        action: "Cancelamento Efetivado - Listar",
      },
    ],
  },
];
