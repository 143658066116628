export default [
  {
    header: "Ensino a Distância",
    icon: "bi-card-list",
  },
  {
    main: "Ensino a Distância",
    title: "Disciplinas EAD",
    icon: "bi-card-list",
    route: "disciplines-list",
    resource: "Ensino a Distância",
    action: "Disciplina - Listar",
  },
  {
    main: "Ensino a Distância",
    title: "Aulas",
    icon: "bi-collection-play-fill",
    route: "lessons-list",
    resource: "Ensino a Distância",
    action: "Aula - Listar",
  },
  {
    main: "Ensino a Distância",
    title: "Materiais EAD",
    icon: "bi-files",
    route: "materials-v1-list",
    resource: "Ensino a Distância",
    action: "Material - Listar",
  },
  {
    main: "Ensino a Distância",
    title: "Aprovados",
    icon: "bi-person-check",
    route: "approved-students-list",
    resource: "Ensino a Distância",
    action: "Aprovado - Listar",
  },
];
