var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "main-menu menu-fixed menu-accordion menu-shadow",
      class: [
        {
          expanded:
            !_vm.isVerticalMenuCollapsed ||
            (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
        },
        _vm.skin === "semi-dark" ? "menu-dark" : "menu-light",
      ],
      on: {
        mouseenter: function ($event) {
          return _vm.updateMouseHovered(true)
        },
        mouseleave: function ($event) {
          return _vm.updateMouseHovered(false)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "navbar-header expanded" },
        [
          _vm._t(
            "header",
            function () {
              return [
                _c("ul", { staticClass: "nav navbar-nav flex-row" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item mr-auto" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "navbar-brand mt-0",
                          attrs: { to: "/" },
                        },
                        [
                          _vm.school.domain === "centraldeconcursos.com.br"
                            ? _c("b-img", {
                                staticClass: "img-fluid logo-seducar",
                                staticStyle: {
                                  width: "130px",
                                  "margin-top": "10px",
                                },
                                attrs: {
                                  src: require("@/assets/images/logo/logo-cc.png"),
                                  alt: "logo",
                                },
                              })
                            : _vm.school.domain === "degraucultural.com.br"
                            ? _c("b-img", {
                                staticClass: "img-fluid logo-seducar",
                                staticStyle: { width: "130px" },
                                attrs: {
                                  src: require("@/assets/images/logo/logo-dc.webp"),
                                  alt: "logo",
                                },
                              })
                            : _c("b-img", {
                                staticClass: "img-fluid logo-seducar",
                                attrs: { src: _vm.appLogoImage, alt: "logo" },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item nav-toggle" },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "nav-link modern-nav-toggle text-center",
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "d-block d-xl-none",
                            staticStyle: { margin: "auto" },
                            attrs: { icon: "XIcon", size: "20" },
                            on: { click: _vm.toggleVerticalMenuActive },
                          }),
                          _c("feather-icon", {
                            staticClass:
                              "d-none d-xl-block collapse-toggle-icon",
                            staticStyle: { margin: "auto" },
                            attrs: {
                              icon: _vm.collapseTogglerIconFeather,
                              size: "20",
                            },
                            on: { click: _vm.toggleCollapsed },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            {
              toggleVerticalMenuActive: _vm.toggleVerticalMenuActive,
              toggleCollapsed: _vm.toggleCollapsed,
              collapseTogglerIcon: _vm.collapseTogglerIcon,
            }
          ),
        ],
        2
      ),
      _c("div", {
        staticClass: "shadow-bottom",
        class: { "d-block": _vm.shallShadowBottom },
      }),
      _c(
        "vue-perfect-scrollbar",
        {
          staticClass: "main-menu-content scroll-area",
          attrs: { settings: _vm.perfectScrollbarSettings, tagname: "ul" },
          on: {
            "ps-scroll-y": function (evt) {
              _vm.shallShadowBottom = evt.srcElement.scrollTop > 0
            },
          },
        },
        [
          _c("vertical-nav-menu-items", {
            staticClass: "navigation navigation-primary navigation-main",
            attrs: { items: _vm.navMenuItems },
          }),
          _vm.school.logo
            ? _c(
                "div",
                {
                  staticClass: "form-row",
                  staticStyle: {
                    background: "#f1f2f36e",
                    "border-radius": "6px",
                    padding: "10px",
                    margin: "20px 20px",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-12 text-center" },
                    [
                      _c("b-img", {
                        staticClass: "img-fluid logo-seducar",
                        staticStyle: { "max-width": "120px" },
                        attrs: { src: _vm.appLogoImage, alt: "logo" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-12 text-center" }, [
                    _vm._v("© 2022 Seducar EAD"),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }